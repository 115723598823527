import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Phase 1",
      title: "Concept",
      info: [
        "Develop the $BPEPE concept",
        "Create an engaging website",
        "Launch social media",
        "Grow a loyal supporter base",
      ],
    },
    {
      roadmapTitle: "Phase 2",
      title: "Pre-Launch",
      info: [
        "Complete initial security audit.",
        "Structure the presale steps",
        "Strengthen investor ties",
      ],
    },

    {
      roadmapTitle: "Phase 3",
      title: "Launch",
      info: [
        "Public testnet launch",
        "Add $BPEPE to CMC and CG",
        "Integrate Dexscreener, Dextools, etc",
        "Mini-Game P2E Reveal",
      ],
    },

    {
      roadmapTitle: "Phase 4",
      title: "Growth",
      info: [
        "Complete CertiK certification",
        "Exchange Listings: Gate.IO and MEXC",
        "10.000 Holders",
      ],
    },
    {
      roadmapTitle: "Phase 5",
      title: "Features",
      info: [
        "Launch $BPEPE staking",
        "Add HTX and LBank  listings",
        "Game Development: Advance the P2E game",
      ],
    },
    {
      roadmapTitle: "Phase 6",
      title: "Expansion",
      info: [
        "Add KuCoin exchange",
        "50.000 Holders",
        "Scale international reach",
      ],
    },
    {
      roadmapTitle: "Phase 7",
      title: "Future",
      info: [
        "Enhance platform tools",
        "Collaborate with top projects",
        "100.000 Holders",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
                Baby Pepe Strategy and <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              // ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
