import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { handleClickScroll, scrollToTop } from "../../lib/helpers";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>Don’t miss out, hop into the $BPEPE revolution today!</p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://t.me/babypepeEVM" target="_blank">
                          <img
                            className="footer-icons"
                            src="telegram_logo.png"
                          />
                        </a>
                      </li>

                      <li>
                        <a href="https://x.com/BABYPEPE_evm" target="_blank">
                          <img className="footer-icons" src="1X.png" />
                        </a>
                      </li>

                      {/* <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link
                          to="/"
                          onClick={() => handleClickScroll("header")}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={() => handleClickScroll("about")}>
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={() => handleClickScroll("sales")}>
                          Tokenomics
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/"
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          Roadmap
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/"
                          onClick={() => handleClickScroll("header")}
                        >
                          Buy now
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title"></h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/"></Link>
                      </li>
                      <li>
                        <Link to="/"></Link>
                      </li>
                      <li>
                        <Link to="/"></Link>
                      </li>
                      <li>
                        <Link to="/"></Link>
                      </li>
                      <li>
                        <Link to="/"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                ></div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2024. All Rights Reserved Baby Pepe</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#"></Link>
                    </li>
                    <li>
                      <Link to="#"></Link>
                    </li>
                    <li>
                      <Link to="#"></Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
